import React, { useContext, useEffect } from 'react';
import { useLocation , useNavigate} from 'react-router-dom';
import DataContext from '../Reusables/DataContext';
import { translations } from '../Reusables/Utilities';
import { useLanguage } from '../Reusables/LanguageProvider';
import BurgerMenuComponent from '../Reusables/BurgerMenuComponent';

const GrammarContent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { topicName } = location.state || { topicName: ''};
  const { grammarContent, fetchGrammarContent, setGrammarContent } = useContext(DataContext);
  const { selectedLanguage } = useLanguage();

  useEffect(() => {    
    fetchGrammarContent(topicName, selectedLanguage);
  }, [topicName]);

  const handleClick = (linkName) => {
    setGrammarContent([]);
    navigate('/grammar-topic', { state: { topicName: linkName }});
  };

  useEffect(() => {
    const links = document.querySelectorAll('.link');
    const handleClickWrapper = (event) => {
      const linkName = event.target.getAttribute('data-topic');
      handleClick(linkName);
    };

    links.forEach(link => {
      link.addEventListener('click', handleClickWrapper);
    });

    // Clean up event listeners on component unmount
    return () => {
      links.forEach(link => {
        link.removeEventListener('click', handleClickWrapper);
      });
    };
  }, [grammarContent]);

  const content = `<p><b>Exercise Goal:</b> learn about articles and conjugations in ''Le présent''.</p>
<br/>
<p><b>There are three groups of articles:</b></p>
<br/>
<p><b>Articles indéfinis</b> (un / une / des) are used to:</p>
  <ol>
    <li>talk about something non-specific</li>
  	<li>mention something for the first time</li>
  </ol>
  <p>''un'' is used for masculine singular; ''une'' is used for feminine singular; ''des'' is used for plural</p>
  <ul>
   <li>un chat (masculine singular)</li>
   <li>une glace (feminine singular)</li>
   <li>des glaces (plural for both genders) 
      <br/>&nbsp&nbsp&nbsp&nbsp&nbspBUT: if there is an adjective before a plural noun, ''des'' becomes ''de''. For example: de bonnes glaces (good ice creams)</li>
  </ul>
<br/>
<p><b>Articles définis</b> (le / la / les) are used:</p>
  <ol>
    <li>to talk about a specific person or thing</li>
  	<li>to refer to a person or thing that has already been mentioned or is already known</li>
    <li>after the verbs aimer, adorer, préférer, détester</li>
  </ol>
  <p>''le'' is used for masculine singular; ''la'' is used for feminine singular; ''les'' is used for plural</p>
  <ul>
   <li>le chat (masculine singular)</li>
   <li>la glace (feminine singular)</li>
   <li>l''homme (''le'' or ''la'' becomes ''l’'' before a vowel or a mute h)</li>
   <li>les glaces (plural for both genders)</li>
   <li>la glace au chocolate (chocolate ice cream). ("à + le" = "au")</li>
   <li>attention aux ballons (watch out for the balloons)("à + les" = "aux")</li>
  </ul>
<br/>
<p><b>L''article partitif </b>(de l’ / du / de la) are used:</p>
  <ol>
    <li>with uncountable nouns</li>
  	<li>to talk about sports and musical instruments</li>
  </ol>
  <ul>
   <li>de l’eau (nouns start with a vowel)</li>
   <li>du café (masculine ''du'' = ''de + le'')</li>
   <li>de la flûte (feminine)</li>
  </ul>
<br/>
<p><b>Conjugations in ''Le présent''</b></p>
<p>For verbs ending with -er: the ending -er changes to -e, -es, -e, -ons, -ez, -ent:</p>
<table class="table-content">
    <tr>
        <td>J''aim<b>e</b></td>
        <td>silent ending -e <button class="listen-button" style="scale:0.8" data-audio="/Audio/Dictionary/A/aimer.mp3">
            <img src="Icons/listenDictionary.svg" alt="Listen"/>
          </button></td>
    </tr>
    <tr>
        <td>Tu aim<b>es</b></td>
        <td>silent ending -e <button class="listen-button" style="scale:0.8" data-audio="/Audio/Dictionary/A/aimes.mp3">
            <img src="Icons/listenDictionary.svg" alt="Listen"/>
          </button></td>
    </tr>
    <tr>
        <td>Il/elle/on aim<b>e</b></td>
        <td>silent ending -e <button class="listen-button" style="scale:0.8" data-audio="/Audio/Dictionary/A/aime.mp3">
            <img src="Icons/listenDictionary.svg" alt="Listen"/>
          </button></td>
    </tr>
    <tr>
        <td>Nous aim<b>ons</b></td>
        <td>ending pronounced as ''on''; silent ''s''<button class="listen-button" style="scale:0.8" data-audio="/Audio/Dictionary/A/aimons.mp3">
            <img src="Icons/listenDictionary.svg" alt="Listen"/>
          </button></td>
    </tr>
    <tr>
        <td>Vous aim<b>ez</b></td>
        <td>same pronounciation as ''aimer'' <button class="listen-button" style="scale:0.8" data-audio="/Audio/Dictionary/A/aimez.mp3">
            <img src="Icons/listenDictionary.svg" alt="Listen"/>
          </button></td>
    </tr>
    <tr>
        <td>Ils/elles	aim<b>ent</b> </td>
        <td>silent ending -ent <button class="listen-button" style="scale:0.8" data-audio="/Audio/Dictionary/A/aiment.mp3">
            <img src="Icons/listenDictionary.svg" alt="Listen"/>
          </button></td>
    </tr>
</table>		
<p>Only ending -ons and -ez are pronounced. So, ''aime'', ''aimes'', and ''aiment'' have the same pronunciation.
<br/><br/>
The word ''ouvrir'' which means to open, does not follow rules for -ir verbs. Indeed, it has the same conjugations as ''aimer'':
<br/>&emsp;&emsp;J''ouvr<b>e</b> <button class="listen-button" style="scale:0.8" data-audio="/Audio/Dictionary/O/ouvre.mp3">
            <img src="Icons/listenDictionary.svg" alt="Listen"/>
          </button>
<br/>&emsp;&emsp;Tu ouvr<b>es</b> <button class="listen-button" style="scale:0.8" data-audio="/Audio/Dictionary/O/ouvres.mp3">
            <img src="Icons/listenDictionary.svg" alt="Listen"/>
          </button>
<br/>&emsp;&emsp;Il ouvr<b>e</b> <button class="listen-button" style="scale:0.8" data-audio="/Audio/Dictionary/O/ouvre.mp3">
            <img src="Icons/listenDictionary.svg" alt="Listen"/>
          </button>
<br/>&emsp;&emsp;Nous ouvr<b>ons</b> <button class="listen-button" style="scale:0.8" data-audio="/Audio/Dictionary/O/ouvrons.mp3">
            <img src="Icons/listenDictionary.svg" alt="Listen"/>
          </button>
<br/>&emsp;&emsp;Vous ouvr<b>ez</b> <button class="listen-button" style="scale:0.8" data-audio="/Audio/Dictionary/O/ouvrez.mp3">
            <img src="Icons/listenDictionary.svg" alt="Listen"/>
          </button>
<br/>&emsp;&emsp;Ils ouvr<b>ent</b> <button class="listen-button" style="scale:0.8" data-audio="/Audio/Dictionary/O/ouvrent.mp3">
            <img src="Icons/listenDictionary.svg" alt="Listen"/>
          </button></p>
<br/>
<p><b>In the next pages,</b> you can hear the audio by pressing <img src="Icons/listenDictionary.svg" alt="{translations[selectedLanguage].Listen}"/> and hear it slower by pressing <img src="Icons/turtle_small.svg" alt="{translations[selectedLanguage].Slow}"/> </p>
<br/>	<p>You can click on each word to learn its pronunciation, see its meaning, and record yourself speaking that word.</p>
<br/>	<p>Tap the microphone to record yourself reading aloud the sentence. Press it again to stop recording. You will see an immediate feedback of your pronunciation. Enjoy!</p>`;

  return (   
    <div className='grammar-page'>
      <div className="top-row">
        <button onClick={() => navigate(-1)} className="back-button"> <img src={`/Icons/arrow-left.svg?${new Date().getTime()}`} alt={translations[selectedLanguage].back} />  </button>
        <h3>{topicName}</h3>
        <div><BurgerMenuComponent /></div>  
      </div> 
      <div className='grammar-content'>
        <div >
          <br/> <br/>
          <div dir={(selectedLanguage === 'ar' || selectedLanguage === 'fa') ? 'rtl' : 'ltr'} dangerouslySetInnerHTML={{ __html: grammarContent }} />
          <br/><br/>        <div dangerouslySetInnerHTML={{ __html: content }} /> 
        </div>
      </div>  
    </div>    
  );
};

export default GrammarContent;
