import React, {useContext, useState} from 'react';
import { useEffect} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useUserAnswers } from '../Practice/UserAnswers';
import { translations, getTopicName } from '../Reusables/Utilities';
import { useLanguage } from '../Reusables/LanguageProvider';
import './TopicSelection.css'; 
import DataContext from '../Reusables/DataContext';
import BurgerMenuComponent from '../Reusables/BurgerMenuComponent';

const TopicSelection = () => {
  let navigate = useNavigate();
  const { level, updateExercise, updateTopic, updateEnglishTopic} = useUserAnswers();
  const { topics, fetchTopics, exercises, fetchExercisesGroups, sentencesForExercise, setSentencesForExercise, sentencesForStudy, setSentencesForStudy, setConversations} = useContext(DataContext);
  const { selectedLanguage } = useLanguage();
  const [clickTopic, setClickTopic] = useState(false);
  const [clickExercise, setClickExercise] = useState(false);
  const [clickConversations, setClickConversations] = useState(true);
  const [clickExercises, setClickExercises] = useState(true);
  
  useEffect(() => {    
    fetchTopics(level);
    fetchExercisesGroups(level);
  }, []);

  useEffect(() => {    
    if (clickExercise && sentencesForExercise.length === 0){
      setClickExercise(false);
      navigate('/exercise-instruction', { state: { fromTopicSelection: true } });
    }
  }, [clickExercise]);

  const handleClickExercise = (exerciseName) => {   
    setSentencesForStudy([]);
    setConversations([]); 
    setSentencesForExercise([]);
    updateTopic('');
    updateExercise(exerciseName);
    setClickExercise(true);    
  };
  
  useEffect(() => {    
    if (clickTopic && sentencesForStudy.length === 0){
      setClickTopic(false);
      navigate('/speaking-sentences', { state: { index: 0 } });
    }
  }, [clickTopic]);  
  
  const handleClickTopic = (topicName, topicNameEn) => {    
    setSentencesForStudy([]);
    setConversations([]);
    setSentencesForExercise([]);
    updateExercise('');
    updateTopic(topicName);
    updateEnglishTopic(topicNameEn);
    setClickTopic(true);    
  };

  const location = useLocation();  
  const {  fromCompletionPage } = location.state || { fromCompletionPage: false};  
  const goBack = () => {        
    if (fromCompletionPage) { 
      navigate('/completion-page'); 
    } else {      
      navigate('/');      
    }
  };

  const handleClickExercises = () => {
    setClickExercises(!clickExercises);
  };

  const handleClickConversations = () => {
    setClickConversations(!clickConversations);
  };

  return (    
    <div className="topic-container">
      {level !== 0 ? (
         <div>
        <div className="top-row">
          <button onClick={() => goBack()} className="back-button"> <img src={`/Icons/arrow-left.svg?${new Date().getTime()}`} alt={translations[selectedLanguage].back} />  </button>
          <h3>{translations[selectedLanguage].chooseATopic}</h3>
          <div><BurgerMenuComponent /></div>  
        </div> 
    
        <div className="study-body">
          <button
            className="topic-options"
            onClick={() => handleClickExercises()}
          >
            <img  src={`/Icons/exercises.svg?${new Date().getTime()}`} alt="" />&nbsp; 
            <span>  {translations[selectedLanguage].Exercises}</span>
            <span style={{marginLeft:"auto"}}>{clickExercises ? ( 
                  <img src={`/Icons/arrow-up.svg?${new Date().getTime()}`} alt={translations[selectedLanguage].collapse} />
                ) : (
                  <img src={`/Icons/arrow-down.svg?${new Date().getTime()}`} alt={translations[selectedLanguage].expand} />
                )}</span>
          </button>
  
          {clickExercises && exercises.map((exercise, index) => (
              <div key={exercise.id}  className="topics">
              <button className="topic-button" 
                onClick={() => handleClickExercise(exercise.exercise_name_fr)} >
                <div style={{marginRight:"auto"}}>{exercise.exercise_name_fr} </div>
                <div style={{marginLeft:"auto"}}><img src={`/Icons/arrow-right.svg?${new Date().getTime()}`} alt={translations[selectedLanguage].next} /></div>
              </button>    
              {index < exercises.length - 1 && <hr />}
              </div>            
          ))}

          <button
            className="topic-options"
            onClick={() => handleClickConversations()}
          >
            &nbsp;<img  src={`/Icons/conversations.svg?${new Date().getTime()}`} alt="" />&nbsp; 
            <span>  {translations[selectedLanguage].Conversations}</span>
            <span style={{marginLeft:"auto"}}>{clickConversations ? ( 
                  <img src={`/Icons/arrow-up.svg?${new Date().getTime()}`} alt={translations[selectedLanguage].collapse} />
                ) : (
                  <img src={`/Icons/arrow-down.svg?${new Date().getTime()}`} alt={translations[selectedLanguage].expand} />
                )}</span>
          </button>
  
          {clickConversations && topics.map((topic, index) => (
              <div key={topic.id || getTopicName(topic, selectedLanguage)}  className="topics">
              <button className="topic-button" 
                onClick={() => handleClickTopic(getTopicName(topic, selectedLanguage), getTopicName(topic, 'en'))} >
                <div style={{marginRight:"auto"}}>{getTopicName(topic, selectedLanguage)} </div>
                <div style={{marginLeft:"auto"}}><img src={`/Icons/arrow-right.svg?${new Date().getTime()}`} alt={translations[selectedLanguage].next} /></div>
              </button>    
              {index < topics.length - 1 && <hr />}
              </div>            
          ))}
        </div>  
        </div>      
        ) : (
          <button className="topic-option" onClick={() => navigate('/')}>{translations[selectedLanguage].PleaseChooseALevel}</button>        
        )} 
    </div>
  );
}

export default TopicSelection;