import React, { createContext, useState, useContext, useEffect, useRef } from 'react';
import { showSweetAlert, translations } from './Utilities';

const RecordUserContext = createContext();

export const useRecordUser = () => useContext(RecordUserContext);

export const RecordUserProvider = ({ children }) => {
  const [recording, setRecording] = useState(false);
  const [transcription, setTranscription] = useState('');
  const [error, setError] = useState(null);
  const [ready, setReady] = useState(false);
  const [expectedSentence, setExpectedSentence] = useState('');
  const audioContextRef = useRef(null);
  const analyserRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const silenceTimerRef = useRef(null);
  const autoStopTimerRef = useRef(null);
  const audioChunksRef = useRef([]);
  const silenceStartTimeRef = useRef(null);
  const streamRef = useRef(null);
  const shouldSendToServerRef = useRef(true);
  const [selectedLanguage, setSelectedLanguage] = useState('');

  const silenceThreshold = 0.005; // RMS threshold to detect silence
  const silenceGracePeriod = 3000; // Grace period of continuous silence before stopping (in milliseconds)
  const autoStopDuration = 15000; 

   const initializeAudioContext = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        const audioContext = new (window.AudioContext || window.webkitAudioContext)();
        const analyser = audioContext.createAnalyser();
        const source = audioContext.createMediaStreamSource(stream);

        analyser.fftSize = 2048;
        analyserRef.current = analyser;
        audioContextRef.current = audioContext;
        streamRef.current = stream;

        source.connect(analyser);
        setReady(true);
        console.log('Audio context and media stream initialized.');
      } catch (error) {
        setError(`Error initializing audio context: ${error.message}`);
        console.error('Error initializing audio context:', error);
      }
  };

  const startRecording = async (selectedLanguage) => {       
    console.log("recordReady = " + ready);
    if (!ready ) {
      await initializeAudioContext();
      setSelectedLanguage(selectedLanguage);
    }

    try {
      console.time('Total Recording Time');
      resetState();

      const stream = streamRef.current;
      
      if (!stream) {
        throw new Error('MediaStream is not available');
      }

      const mediaRecorder = new MediaRecorder(stream);
      mediaRecorderRef.current = mediaRecorder;

      mediaRecorder.ondataavailable = (event) => {
        audioChunksRef.current.push(event.data);
      };

      mediaRecorder.onstop = async () => {
        console.time('Blob Creation Time');
        const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
        audioChunksRef.current = [];
        console.timeEnd('Blob Creation Time');
        if (shouldSendToServerRef.current) {
          await sendAudioToServer(audioBlob);
        } else {
          console.log('Recording stopped before completion; not sending to server.');
        }
      };

      mediaRecorder.start();
      setRecording(true);
      shouldSendToServerRef.current = true;
      console.log('Recording started.');

      silenceTimerRef.current = setInterval(checkSilence, 100);
      autoStopTimerRef.current = setTimeout(stopRecording, autoStopDuration);
    } catch (error) {
      setError(`Error starting recording: ${error.message}`);
      console.error('Error starting recording:', error);
    }
  };

  // const startRecordingWord = (expectedSentence) => {
  //   setSpeakWord(true);
  //   setExpectedSentence(expectedSentence);
  //   startRecording();
  // }
  // const startRecordingSentence = (expectedSentence) => {
  //   setExpectedSentence(expectedSentence);
  //   startRecording();
  // }

  const stopRecording = () => {
    setRecording(false);
    clearInterval(silenceTimerRef.current);
    clearTimeout(autoStopTimerRef.current);
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      //mediaRecorderRef.current.stream.getTracks().forEach(track => track.stop());
    }
    //setReady(false);
    console.timeEnd('Total Recording Time');
    console.log('Recording stopped.');
  };
  
  const nagivateAway = () => {
    setRecording(false);
    clearInterval(silenceTimerRef.current);
    clearTimeout(autoStopTimerRef.current);
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      mediaRecorderRef.current.stream.getTracks().forEach(track => track.stop());
    }
    setReady(false);
    console.timeEnd('Total Recording Time');
    console.log('Recording stopped.');
  };

  const resetState = () => {
    clearInterval(silenceTimerRef.current);
    clearTimeout(autoStopTimerRef.current);
    audioChunksRef.current = [];
    silenceStartTimeRef.current = null;
    if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
      mediaRecorderRef.current.stop();
    }
    console.log('State reset.');
  };

  const checkSilence = () => {
    const analyser = analyserRef.current;
    const bufferLength = analyser.fftSize;
    const dataArray = new Uint8Array(bufferLength);
    analyser.getByteTimeDomainData(dataArray);

    let sum = 0;
    for (let i = 0; i < bufferLength; i++) {
      const x = dataArray[i] / 128.0 - 1.0;
      sum += x * x;
    }
    const rms = Math.sqrt(sum / bufferLength);

    if (rms < silenceThreshold) {
      if (!silenceStartTimeRef.current) {
        silenceStartTimeRef.current = Date.now();
      } else if (Date.now() - silenceStartTimeRef.current > silenceGracePeriod) {
        showSweetAlert(translations[selectedLanguage].SpeakLouder, 'practice-message');
        stopRecording();
        console.log('Silence detected, stopping recording.');
      }
    } else {
      silenceStartTimeRef.current = null;
    }
  };

  const sendAudioToServer = async (audioBlob) => {
    console.time('Uploading Audio');
    const formData = new FormData();
    formData.append('audio', audioBlob, 'recording.wav');
    // formData.append('expected_sentence', expectedSentence); // Add expected sentence to form data

    console.log('Sending audio to server...');
    console.log('Blob size:', audioBlob.size);

    try {
      console.time('Server Round-Trip Time');
      const response = await fetch('https://demotest.aiducare.com/upload-audio', {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to upload audio. Server responded with: ${errorText}`);
      }
      
      if (!shouldSendToServerRef.current) {
          console.log('Upload canceled.');
          return;
        }

      const jsonResponse = await response.json();
      console.timeEnd('Server Round-Trip Time');
      console.timeEnd('Uploading Audio');
      if (shouldSendToServerRef.current && jsonResponse.transcription !== '') {
        setTranscription(jsonResponse.transcription || JSON.stringify(jsonResponse));
      } else {
        console.log('Upload canceled after response received.');
        showSweetAlert(translations[selectedLanguage].SpeakLouder, 'practice-message');
      }
    } catch (error) {
      console.timeEnd('Server Round-Trip Time');
      console.timeEnd('Uploading Audio');
      console.error('Error uploading audio:', error);
      setError(`Error uploading audio: ${error.message}`);
    }
  };

  // Cleanup on unmount
  // const closeAudio = () => {      
  //   if(audioContextRef && audioContextRef.current){               
  //     audioContextRef.current?.close(); 
  //     if (mediaRecorderRef.current) {
  //       stopRecording(); 
  //       mediaRecorderRef.current.stream.getTracks().forEach(track => track.stop());
  //     }
  //   }         
  // };

  // this function is to remove red button on top of browser tap
  const closeAudio = () => {      
    if (audioContextRef.current && typeof audioContextRef.current.close === 'function') {
      audioContextRef.current.close().then(() => {
        audioContextRef.current = null; // Reset the ref
      }).catch((error) => {
        console.error("Error closing AudioContext:", error);
      });
    } else {
      audioContextRef.current = null; // If close is unsupported, reset anyway
    }
    
    if (mediaRecorderRef.current) {
      stopRecording();
      mediaRecorderRef.current.stream.getTracks().forEach(track => track.stop());
      mediaRecorderRef.current = null; // Reset the ref
    }

    setReady(false);
};


  return (
    <RecordUserContext.Provider value={{ setTranscription, setExpectedSentence,  recording, transcription, error, startRecording, stopRecording, shouldSendToServerRef, nagivateAway, ready, closeAudio }}>
      {children}
    </RecordUserContext.Provider>
  );
};
